import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { DeploymentObject} from 'api';
import { EKSClusterStatus, SDSDeployerStatus, SdsDeploymentInfo } from 'src/app/deployments/sds/model/sds-deployment-info';
import { ConfirmationDialogComponent, ConfirmationDialogConfig } from '@intersystems/confirmation-dialog';
import { FrostButton } from '@intersystems/shared-utilities/button';
import { DeploymentInfo } from 'src/app/deployments/icca-common/model/deployment-info';
import { IccaCommonService } from 'src/app/deployments/icca-common/services/icca-common.service';
import { of, Subscription } from 'rxjs';
import { catchError, delay, map, repeatWhen, takeWhile, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SharedService } from 'src/app/shared/services/shared.service';
import { MatDialog } from '@angular/material/dialog';
import { DeploymentsService } from 'src/app/deployments/deployments.service';
import { BillingInfo } from 'src/app/deployments/icca-common/model/billing-info';
import { AuthService } from 'src/app/core/auth.service';
import { SUBSCRIPTIONS_CODES } from 'src/app/shared/subscriptions-data';
import { DeploymentLogsDialogComponent } from '../dialogs/deployment-logs/deployment-logs-dialog.component';
@Component({
  selector: 'app-deployment-card',
  templateUrl: './deployment-card.component.html',
  styleUrls: ['./deployment-card.component.scss'],
})
export class DeploymentCardComponent implements OnInit, OnDestroy {
  @Input() deployment: DeploymentObject = null;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  @Input() currentTenantData: any;
  
  @Output() deleted = new EventEmitter<any>();
  @Output() selected = new EventEmitter<any>();
  @Output() servicelevel = new EventEmitter<any>();
  @Output() update = new EventEmitter<any>();

  constructor(
    private iccaCommonService: IccaCommonService,
    private dialog: MatDialog,
    private deploymentsService: DeploymentsService,
    private sharedService: SharedService,
    private authService: AuthService,
    ) {}

  private sub = new Subscription();

  public deploymentInfo: DeploymentInfo | undefined | SdsDeploymentInfo;
  public deploymentErrorStatus: "notFound" | "deleting" | "" = "";
  public deploymentFailed:boolean = false;
  public billingInfo: BillingInfo | undefined;
  iccaTrial:boolean=false;
  user:any;
  
  getLogsButtonconfig: FrostButton = {
    hidden: false,
    type: "submit",
    buttonClass: "primary",
    text: "Get Logs",
    tooltip: "Show deployment logs",
    callback: (event, button) => {
      this.showLogs()
    }
  };

  retryButtonConfig: FrostButton = {
    hidden: false,
    type: "submit",
    buttonClass: "primary",
    text: "Retry",
    tooltip: "Retry failed deployment",
    disabled: () => !this.deploymentFailed,
    callback: (event, button) => {
      this.deploymentsService.retryDeployment(this.deployment.deploymenttype, this.deployment.deploymentid, this.deployment.region)
      .subscribe(
        retryResponse => {
          this.sharedService.showSuccess("Retrying Deployment")
        },
        err =>{
          this.sharedService.showAlert('Problems retrying deployment')  
        }
      );
    }
  };

  ngOnInit(): void {
    this.user=this.authService.user;
    //set deploymentInfo object
    if (this.deploymentsService.getOfferArchitecture(this.deployment.deploymenttype) == 'icca') {
      this.sub.add(this.iccaCommonService.loadDeploymentInfo$(this.deployment.deploymenttype,
        this.deployment.deploymentid,
        this.deployment.region).pipe(
        repeatWhen(obs => obs.pipe(delay(10000))),
        tap(deploymentInfo => {
          this.deploymentInfo = deploymentInfo;
          this.checkforServiceMismatch(this.deployment,true);
        }),
      ).subscribe(
      deploymentInfo => {},
      //lambda will return 404 if deployment is deleted
      err =>{
        if (err?.status==404) {
          this.deploymentErrorStatus="notFound";
        }
        else if ((err?.status==500)&&(err?.error.error=='Error occurred fetching cluster. Incomplete deployment creation or incomplete deployment deletion. Please delete deployment.')) {
          this.deploymentErrorStatus="deleting";
        }
        else {
          this.sharedService.showAlert('Error getting deployment info: ' + err.error.error);
        }     
      }));
      
      this.sub.add(this.iccaCommonService.loadBillingInfo$(this.deployment.deploymenttype).pipe(
        repeatWhen(obs => obs.pipe(delay(60000))),
        tap(billingInfo => {
          billingInfo.balance=Math.floor(billingInfo.balance);
          this.billingInfo = billingInfo;
        }),
      ).subscribe());
    }    

    if (this.deployment.deploymenttype=='sds') {
      this.sub.add(this.deploymentsService.skipperDeploymentInfo$(this.deployment.region,this.deployment.deploymenttype,this.deployment.deploymentid).pipe(
        repeatWhen(obs => obs.pipe(delay(10000))),
        map(deploymentInfo => deploymentInfo as SdsDeploymentInfo),
        tap(deploymentInfo => {
          this.deploymentInfo = deploymentInfo;
        }),
        takeWhile(
          deploymentStatusInfo => (!deploymentStatusInfo) || (deploymentStatusInfo.eksClusterStatus != EKSClusterStatus.ACTIVE)
        )
      ).subscribe(deploymentInfo => {
        this.deploymentFailed = deploymentInfo.deployerStatus.toUpperCase() === SDSDeployerStatus.FAILED
      },
      //lambda will return 406 if deployment is deleted
      err =>{
        if (err?.error?.error=='error getting info about sts: pipeline not found') {
          this.deploymentErrorStatus="notFound";
        }    
        
      }));
    }
    
    //only show trial balance for sqlaas deployments
    this.iccaTrial=((this.user.subscriptions[SUBSCRIPTIONS_CODES.sqlaas]?.terms?.title=='trial')&&(this.deployment.deploymenttype=='sqlaas'));
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.sub.unsubscribe();
 }
 
  deleteClick($event) {
    this.deleted.emit();
  }

  servicelevelClick($event) {
    this.servicelevel.emit();
  }

  // Called by Get Logs button to show a user the last 50 deployment related logs.
  // This functionality will only be visible on SDS deployment cards
  showLogs() {
    this.deploymentsService.getSkipperLogs(
      this.deployment.deploymenttype,
      this.deployment.region,
      this.deployment.deploymentid,
      50
    )
    .pipe(
      map(logsResponse => {
        let returnResponse = [{
          "Message": "No Event Logs exist for this deployment."
        }]
        if(logsResponse.events && logsResponse.events.length > 0){
          returnResponse = logsResponse.events
        }
        return returnResponse
      }),
      catchError(err => of([{
          "Message": "Error retrieving The logs for this deployment."
      }]))
    ).subscribe(logsResponse => {

      const dialogConfig: ConfirmationDialogConfig = {
        title: "Logs for " + this.deployment.deploymentname || "Deployment",
        tertiary: logsResponse.map(log => log.Message).join("\n"),
      };

      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: dialogConfig,
        panelClass: "fr-layout-wrapper-mat-dialog-panel",
        minHeight: '50vh',
        maxHeight: '75vh',
        minWidth: '50vw',
        maxWidth: '50vw'
      });

    })
  }

  
  updateversionClick($event) {
    this.update.emit();
  }

  
  getDeploymentStatus(deployment: DeploymentObject, deploymentInfo: DeploymentInfo | SdsDeploymentInfo) {
    if (!deployment) return '';
    if (deployment.deploymenttype == 'fhiraas' && deployment.status == 'COMPLETE' && deployment.service_status != false)
      return 'Status: ' + deployment.service_status;

    if (this.deploymentsService.getOfferArchitecture(deployment.deploymenttype) == 'icca') {
      if (!deploymentInfo) return '';
      if (this.deploymentInfo.info.deploymentState) {
        return 'Status: ' + this.deploymentInfo.info.deploymentState.state;
      } else if (this.deploymentInfo.info.pipelineState) {
        return 'Status: ' + this.deploymentInfo.info.pipelineState?.state;
      } else {
        return '';
      }
    }

    // If you are getting the status of a SDS deployment display the status of the deployer until it completes. Once it completes
    // Display the status of the EKSCluster.
    if (this.deployment.deploymenttype=='sds') {
      if(deploymentInfo['deployerStatus']) {
        let currentStatusUpper = deploymentInfo['deployerStatus'].toUpperCase()
        
        // If the deployer is running then show Deploying so the user is not confused
        if (currentStatusUpper === SDSDeployerStatus.RUNNING) {
          return 'Status: ' + SDSDeployerStatus.DEPLOYING;
        }
        // IF the deployer is complete then show the cluster status
        else if (currentStatusUpper === SDSDeployerStatus.COMPLETE) {
          return 'Status: ' + deploymentInfo['eksClusterStatus'].toUpperCase() || "";
        }
        // Otherwise show the deployer status
        else {
          return 'Status: ' + currentStatusUpper;
        }
      }
    }

    return deployment.status;
  }

  
  isActive(deployment: DeploymentObject) {
    if (!deployment) return false;
    if (this.deploymentsService.getOfferArchitecture(this.deployment.deploymenttype) == 'icca' || deployment.deploymenttype == 'sds') return true;
    //return deployment.status == 'COMPLETE' && deployment.service_status == 'RUNNING';
    if (deployment.deploymenttype == 'fhiraas')
      return deployment.status == 'COMPLETE' && deployment.service_status == 'RUNNING';
    return deployment.status == 'COMPLETE';
  }

  getServiceType(deployment) {
    
    var offer = this.deploymentsService.getOfferDefinition(deployment.deploymenttype);
    let iccaDeploymentType = this.deploymentInfo?.info?.deploymentType;

    if (this.checkforServiceMismatch(deployment)) {
      return `Mismatch ${iccaDeploymentType} / ${deployment.deploymenttype}`;
    } 

    if (iccaDeploymentType != undefined && iccaDeploymentType != deployment.deploymenttype) {
     var subOffer:string = '';
        offer.subOffers.some(function(o) {
          if (o.name === iccaDeploymentType) {
            subOffer=o.caption;
            return true;
          };
        });
        return `${offer.caption} / ${subOffer}`; 
    } else if (offer) {
        return offer.caption;
    }
    
    return deployment.deploymenttype;
  }

  checkforServiceMismatch(deployment, showAlert:boolean=false):boolean {
    //note this scenario should not happen but if a icca deployment is created with a different type
    //than the portal deployment, it will cause issues with the list display.
    //portal=sql, icca=iml is ok becuase iml is a suboffer of sql.
    //portal=doc, icca=iml is not okay because doc does not have a suboffer of iml
    var offer = this.deploymentsService.getOfferDefinition(deployment.deploymenttype);
    let iccaDeploymentType = this.deploymentInfo?.info?.deploymentType;
    if (iccaDeploymentType != undefined && iccaDeploymentType != deployment.deploymenttype) {
      if (!offer.subOffers) {
        if (showAlert) {this.sharedService.showAlert(`Deployment mismatch with ${deployment.deploymentname}.  Please delete the deployment or contact InterSystems Support.`)}
        return true;
      }
    }
    return false;
  }

  canClick() {
    if (this.deploymentsService.getOfferArchitecture(this.deployment.deploymenttype) == 'icca') { 
      if (!this.deploymentInfo) return false;
      return true; //(this.deploymentInfo.info['deploymentState'].state == 'Running' || this.deploymentInfo.info['deploymentState'].state == 'Stopped');
    } else if (this.deployment.deploymenttype == 'sds') {
      if (!this.deploymentInfo) return false;
      return this.deploymentInfo['eksClusterStatus'] == 'ACTIVE';
    } else {
      return this.deployment.status == 'COMPLETE';
    }
  }
  isButtonActive() {
    return this.canDelete() || this.canChangeServiceLevel();
  }
  canDelete() {
    return this.deployment.status != 'CREATING' && this.currentTenantData.role == 'admin';
  }
  canUpdate() {
    return (
      this.deployment.status == 'COMPLETE' &&
      this.currentTenantData.role != 'read' &&
      this.deployment.deploymenttype == 'hcc' &&
      environment.APP_URL != 'https://portal.live.isccloud.io'
      // @ssh I need to access the version api here and add the boolen
      // conditional "can_update" here in this method
      //this.version.can_upgrade == True
      //this.deployment.ha_config == 'non-mirrored' &&
      //this.deployment.resource_list['snapupversion'] == this.deployment.resource_list['snapversion']
    );
  }
  canChangeServiceLevel() {
    if (this.deploymentsService.getOfferArchitecture(this.deployment.deploymenttype) == 'icca' || this.deployment.deploymenttype == 'sds') {
      return false;
    } 
    else {
      return (
        this.isActive(this.deployment) && this.currentTenantData.role != 'read' && this.deployment.deploymenttype != 'lol'
      );
    }
  }

  onClick() {
    if (!this.canClick()) return;
    this.selected.emit();
  }

  canGetLogs():boolean {
    //show logs if deployment is icca
    const architecture=this.deploymentsService.getOfferArchitecture(this.deployment.deploymenttype)
    return (architecture=='icca'); // || architecture=='sds')
  }

  getLogsClick($event) {


    this.dialog.open(DeploymentLogsDialogComponent, {
        data: {
          title: "Logs for " + this.deployment.deploymentname + " (Deployment id: " + this.deployment.deploymentid + ")"|| "Deployment",
          deployment: this.deployment,
          errorStatus: this.deploymentErrorStatus,
        },
        panelClass: 'isc-form-modal',
        autoFocus: false,
        position: {left: '300px', top:'100px'},
        }).afterClosed();
 
  }


  
}
