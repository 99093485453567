import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from '@intersystems/notification';
import { DomSanitizer } from '@angular/platform-browser';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { DayjsDateAdapter, Requirement } from '@intersystems/isc-form';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { SUBSCRIPTIONS_DATA } from '../subscriptions-data';


@Injectable({
  providedIn: 'root',
})
export class SharedService {
  constructor(
    private notificationService: NotificationService,
    private router: Router,
    private sanitizer: DomSanitizer,
  ) {
    //// Fix a bug in FROST, should be removed after upgrade
    DayjsDateAdapter.prototype.parse = (function (_super) {
      return function () {
        // eslint-disable-next-line prefer-rest-params
        if (typeof arguments[1] != 'string') arguments[1] = 'LL';
        // eslint-disable-next-line prefer-rest-params
        return _super.apply(this, arguments);
      };
    })(DayjsDateAdapter.prototype.parse);
  }

  /// Use this refresh$ observable in data services to indicate when data refresh is needed
  private _refresh = new BehaviorSubject<void>(null);
  get refresh$(): Observable<any> {
    return this._refresh.asObservable();
  }

  private clearDataBeforeFilter = new BehaviorSubject<boolean>(false);
  public clearDataBeforeFilter$ = this.clearDataBeforeFilter.asObservable();

  showSuccess(message: string) {
    this.notificationService.showSuccess(message, 5000);
  }

  showAlert(message: string, isPermanent?: boolean) {
    this.notificationService.showAlert(message, isPermanent ? undefined : 5000);
  }

  showInfo(message: string) {
    this.notificationService.showInfo(message, 5000);
  }

  reloadCurrentRoute() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/reload', { skipLocationChange: true }).then(() => {
      this.router.navigateByUrl(currentUrl);
    });
    this._refresh.next();
  }

  emitRefresh() {
    this._refresh.next();
  }

  generateDownloadJsonUrl(json: Record<string, unknown>, sanitized?: boolean) {
    const blob = new Blob([JSON.stringify(json)], { type: 'application/json' });
    const blobUrl = window.URL.createObjectURL(blob);
    return sanitized ? this.sanitizer.bypassSecurityTrustUrl(blobUrl) : blobUrl;
  }
  compareDates(a, b, isAsc) {
    return ((a ? new Date(a).getTime() : '') < (b ? new Date(b).getTime() : '') ? -1 : 1) * (isAsc ? 1 : -1);
  }
  passwordRequirements(): Requirement[] {
    return [
      {
        key: 'length',
        regEx: '.{8,}',
        id: 'length',
        message: 'at least 8 characters',
      },
      {
        key: 'capital',
        regEx: '[A-Z]',
        id: 'capital',
        message: 'an uppercase letter',
      },
      {
        key: 'lower',
        regEx: '[a-z]',
        id: 'lower',
        message: 'a lowercase letter',
      },
      {
        key: 'number',
        id: 'number',
        regEx: '[0-9]',
        message: 'a number',
      },
      {
        key: 'special',
        regEx: '[!#$%&?@^*{}():;.,><`\\[\\]\\\\/\\_~|+=-]',
        id: 'special',
        message: 'a symbol',
      },
    ];
  }

  isFilterChanged(state: boolean): void {
    this.clearDataBeforeFilter.next(state);
  }

  catchError() {
    return catchError(error => {
      if (typeof error.error == 'string') this.showAlert('There was the following error: ' + error.error);
      else this.showAlert('Something went wrong. Please, try again later.');
      return EMPTY;
    });
  }

  static isLive(): boolean {
    return (
      environment?.STAGE == 'live' ||
      environment?.STAGE == 'test' ||
      environment?.STAGE == 'events' ||
      environment?.STAGE == 'startup'
    );
  }

  getTimeStamp(date: Date | string, endDay?: boolean): string {
    if (!date) return null;
    if (typeof date == 'string') date = new Date(date);
    // Convert time to UTC
    date.setUTCMinutes(date.getUTCMinutes() - date.getTimezoneOffset());
    if (endDay) date.setUTCMilliseconds(date.getUTCMilliseconds() - 1);
    return date.toISOString().split('.')[0] + 'Z';
  }

  getSubscriptionsList(userSubscriptions, status: 'active' | 'inactive'): Array<any> {
    const subscriptions = [...SUBSCRIPTIONS_DATA];
    const activeSubscriptions = [];
    const inactiveSubscriptions = [];

    subscriptions.map(subscription => {
      Object.keys(userSubscriptions).includes(subscription.productCode)
        ? activeSubscriptions.push(subscription)
        : inactiveSubscriptions.push(subscription);
    });

    return status === 'active' ? activeSubscriptions : inactiveSubscriptions;
  }

  flattenNestedObj(obj, prefix = '') {
    return Object.keys(obj).reduce((acc, key) => {
      const fullPath = prefix ? `${prefix}.${key}` : key;

      if (typeof obj[key] === 'object') {
        acc.push(...this.flattenNestedObj(obj[key], fullPath));
      } else {
        acc.push({ key: fullPath, value: obj[key] });
      }

      return acc;
    }, []);
  }
}
