import { environment } from 'src/environments/environment';

export const DeploymentOptions = {
  regions: {
    main: {
      aws: {
        default: 'us-east-2',
        supported: [
          'us-east-1',
          'us-east-2',
          'eu-west-1',
          'eu-west-2',
          'eu-west-3',
          'eu-central-1',
          'ca-central-1',
          'us-west-2',
        ],
      },
      azure: {
        default: 'eastus2',
        supported: ['eastus2'],
      },
    },
    icca: {
      aws: {
        default: environment['ICCA_REGIONS'] ? environment['ICCA_REGIONS'][0] : 'us-east-1',
        supported: environment['ICCA_REGIONS'] ? environment['ICCA_REGIONS'] : ['us-east-1', 'eu-central-1'],
      },
    },
    sds: {
      aws: {
        default: 'us-east-1',
        supported: ['us-east-1'],
      },
    },
  },
  sizes: {
    common: [
      {
        name: 'Nano',
        size: 'nano',
        inPreview: true,
        instType: 'c6g.large',
        cores: 2,
        ram: '4GB',
        description: 'Functional review, development workloads.',
      },
      {
        name: 'Extra Small',
        size: 'demo',
        inPreview: true,
        instType: 'm5.large',
        cores: 2,
        ram: '8GB',
        description: 'Workload of up to 1700 messages per minute',
      },
      {
        name: 'Small',
        size: 'small',
        inPreview: false,

        instType: 'm5.2xlarge',
        cores: 8,
        ram: '32GB',
        description: 'Workload of up to 4000 messages per minute',
      },
      {
        name: 'Medium',
        size: 'medium',
        inPreview: false,
        instType: 'r5.4xlarge',
        cores: 16,
        ram: '128GB',
        description: 'Workload of up to 14000 messages per minute',
      },
      {
        name: 'Large',
        size: 'large',
        inPreview: false,
        instType: 'r5.8xlarge',
        cores: 32,
        ram: '256GB',
        description: 'Workload of up to 27000 messages per minute',
      },
      {
        name: 'Extra Large',
        size: 'xLarge',
        inPreview: false,
        instType: 'r5.16xlarge',
        cores: 64,
        ram: '512GB',
        description: 'Workload of up to 52000 messages per minute',
      },
    ],
    icca: {
      default: { name: 'Small', size: 'small', select: true },
      supported: [
        {
          name: 'Small',
          abbreviatedName: 's',
          size: 'small',
          inPreview: true,
          cores: 2,
          ram: '16Gi',
          storage: '50Gi',
          storageInGB: 50,
          description: 'A low-cost development and testing environment with 50GB of storage',
        },
        {
          name: 'Medium',
          abbreviatedName: 'm',
          size: 'medium',
          inPreview: false,
          cores: 4,
          ram: '32Gi',
          storage: '100Gi',
          storageInGB: 100,
          description: 'A higher-capacity testing environment with an 8GB cache and up to 100GB of storage',
        },
        {
          name: 'Large',
          abbreviatedName: 'l',
          size: 'large',
          inPreview: false,
          cores: 8,
          ram: '64Gi',
          storage: '500Gi',
          storageInGB: 500,
          description: 'For production workloads requiring a 16GB cache and up to 500GB of storage',
        },
        {
          name: 'Extra Large',
          abbreviatedName: 'xl',
          size: 'xLarge',
          inPreview: false,
          cores: 16,
          ram: '128Gi',
          storage: '1Ti',
          storageInGB: 1024,
          description: 'For more substantial production workloads requiring a 32GB cache and up to 1TB of storage',
        },
      ],
    },
    hcc: [
      {
        name: 'Micro',
        size: 'micro',
        inPreview: true,
        instType: 'm6i.large',
        cores: 2,
        ram: '8GB',
        description: 'Workload of up to 1700 messages per minute',
      },
      {
        name: 'Demo',
        size: 'demo',
        inPreview: true,
        instType: 'm6i.large',
        cores: 2,
        ram: '8GB',
        description: 'Workload of up to 1700 messages per minute',
      },
      {
        name: 'Small',
        size: 'small',
        inPreview: false,
        instType: 'm6i.xlarge',
        cores: 4,
        ram: '16GB',
        description: 'Workload of up to 4000 messages per minute',
      },
      {
        name: 'Medium',
        size: 'medium',
        inPreview: false,

        instType: 'm6i.2xlarge',
        cores: 8,
        ram: '32GB',
        description: 'Workload of up to 4000 messages per minute',
      },
      {
        name: 'Large',
        size: 'large',
        inPreview: false,
        instType: 'm6i.4xlarge',
        cores: 16,
        ram: '128GB',
        description: 'Workload of up to 14000 messages per minute',
      },
      {
        name: 'Extra Large',
        size: 'exlarge',
        inPreview: false,
        instType: 'm6i.8xlarge',
        cores: 32,
        ram: '256GB',
        description: 'Workload of up to 27000 messages per minute',
      },
    ],
    ics: [
      {
        name: 'Micro',
        size: 'micro',
        inPreview: true,
        instType: 'm6i.large',
        cores: 2,
        ram: '8GB',
        description: 'Workload of up to 1700 messages per minute',
      },
      {
        name: 'Demo',
        size: 'demo',
        inPreview: true,
        instType: 'm6i.large',
        cores: 2,
        ram: '8GB',
        description: 'Workload of up to 1700 messages per minute',
      },
      {
        name: 'Small',
        size: 'small',
        inPreview: false,
        instType: 'm6i.xlarge',
        cores: 4,
        ram: '16GB',
        description: 'Workload of up to 4000 messages per minute',
      },
      {
        name: 'Medium',
        size: 'medium',
        inPreview: false,

        instType: 'm6i.2xlarge',
        cores: 8,
        ram: '32GB',
        description: 'Workload of up to 4000 messages per minute',
      },
      {
        name: 'Large',
        size: 'large',
        inPreview: false,
        instType: 'm6i.4xlarge',
        cores: 16,
        ram: '128GB',
        description: 'Workload of up to 14000 messages per minute',
      },
      {
        name: 'Extra Large',
        size: 'exlarge',
        inPreview: false,
        instType: 'm6i.8xlarge',
        cores: 32,
        ram: '256GB',
        description: 'Workload of up to 27000 messages per minute',
      },
    ],
    business360: [
      {
        name: 'Micro',
        size: 'micro',
        inPreview: true,
        instType: 'azure.large',
        cores: 2,
        ram: '8GB',
        description: 'Good for mom&pop business',
      },
      {
        name: 'Small',
        size: 'small',
        inPreview: false,
        instType: 'azure.xlarge',
        cores: 4,
        ram: '16GB',
        description: 'Workload of up to 4000 messages per minute',
      },
      {
        name: 'Medium',
        size: 'medium',
        inPreview: false,

        instType: 'azure.2xlarge',
        cores: 8,
        ram: '32GB',
        description: 'Workload of up to 4000 messages per minute',
      },
      {
        name: 'Large',
        size: 'large',
        inPreview: false,
        instType: 'azure.4xlarge',
        cores: 16,
        ram: '128GB',
        description: 'Workload of up to 14000 messages per minute',
      },
      {
        name: 'Extra Large',
        size: 'exlarge',
        inPreview: false,
        instType: 'azure.8xlarge',
        cores: 32,
        ram: '256GB',
        description: 'Workload of up to 27000 messages per minute',
      },
    ],
    hspd: [
      {
        name: 'Micro',
        size: 'demo',
        inPreview: true,
        instType: 'm6i.large',
        cores: 2,
        ram: '8GB',
        description: 'Workload of up to 1700 messages per minute',
      },
      {
        name: 'Small',
        size: 'small',
        inPreview: false,
        instType: 'm6i.xlarge',
        cores: 4,
        ram: '16GB',
        description: 'Workload of up to 4000 messages per minute',
      },
      {
        name: 'Medium',
        size: 'medium',
        inPreview: false,

        instType: 'm6i.2xlarge',
        cores: 8,
        ram: '32GB',
        description: 'Workload of up to 4000 messages per minute',
      },
      {
        name: 'Large',
        size: 'large',
        inPreview: false,
        instType: 'm6i.4xlarge',
        cores: 16,
        ram: '128GB',
        description: 'Workload of up to 14000 messages per minute',
      },
      {
        name: 'Extra Large',
        size: 'exlarge',
        inPreview: false,
        instType: 'm6i.8xlarge',
        cores: 32,
        ram: '256GB',
        description: 'Workload of up to 27000 messages per minute',
      },
    ],
  },
  serviceDesc: {
    fhiraas: 'FHIR Server',
    fhirserver: 'Cloud FHIR Server',
    hmts: 'FHIR Transformation Services',
    sqlaas: 'InterSystems IRIS Cloud SQL and Cloud IntegratedML',
    hcc: 'Health Connect Cloud',
    sds: 'Smart Data Services',
    ics: 'InterSystems IRIS Cloud Managed Service',
    lol: 'Network Connect',
    business360: 'Business 360',
    doc: 'Cloud Document Service',
    hspd: 'HealthShare Provider Directory',
  },
  creationTime: {
    fhiraas: '5-7 minutes',
    hmts: '5-7 minutes',
    sqlaas: '1-2 minutes',
    hcc: '10-13 minutes',
    ics: '10-13 minutes',
    hspd: '10-13 minutes',
    lol: '5-7 minutes',
    business360: '3-5 minutes',
  },
};
