<app-loading-spinner *ngIf="!activeSubscriptions || !inactiveSubscriptions"></app-loading-spinner>

<ng-container *ngIf="activeSubscriptions && activeSubscriptions.length > 0">
  <h2>Active subscriptions</h2>

  <div class="subsContainer" fxLayout="column" fxLayout.gt-xs="row">
    <mat-card class="subCard" *ngFor="let item of activeSubscriptions">
      <div class="indicator-panel"></div>
      <mat-card-header>
        <div mat-card-avatar [style]="'background-image: url(' + item.photoPath + '); background-size: cover;'"></div>
        <mat-card-title>{{ item.name }}</mat-card-title>
        <mat-card-subtitle *ngIf="item.terms?.title == 'trial'" [id]="'trial-end-' + item.type">
          Trial ends: {{ item.terms?.end_date | date }}
        </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        {{ item.description }}
      </mat-card-content>
      <mat-card-actions [align]="'end'">
        <button mat-stroked-button color="primary" [id]="'btn-create-deployment-' + item.type"
          (click)="createDeployment.emit(item.type)">
          Create Deployment
        </button>
        <button *ngIf="item.terms?.title == 'trial'" [id]="'btn-subscribe-' + item.type" mat-stroked-button
          color="primary" (click)="subscribe.emit(item)">
          Subscribe
        </button>
      </mat-card-actions>
    </mat-card>
  </div>
</ng-container>

<ng-container *ngIf="inactiveSubscriptions && inactiveSubscriptions.length > 0">
  <h2>All Cloud Services</h2>

  <div class="subsContainer" fxLayout="column" fxLayout.gt-xs="row">
    <div *ngFor="let item of inactiveSubscriptions">
      <mat-card class="subCard" *ngIf="showOffer(item.type)">
        <div class="indicator-panel"></div>
        <mat-card-header>
          <div mat-card-avatar [style]="'background-image: url(' + item.photoPath + '); background-size: cover;'"></div>
          <mat-card-title>{{ item.name }}</mat-card-title>
          <mat-card-subtitle *ngIf="item.isPreview">Preview</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          {{ item.description }}
        </mat-card-content>
        <mat-card-actions [align]="'end'">
          <button mat-stroked-button color="primary" [id]="'btn-early-access-' + item.type"
            (click)="request.emit({ name: item.name, request: 'an early access' })" *ngIf="item.isPreview">
            Request Early Access
          </button>
          <button mat-stroked-button color="primary" [id]="'btn-access-' + item.type"
            (click)="request.emit({ name: item.name, request: 'an access' })" *ngIf="item.isInviteOnly">
            Request Access
          </button>
          <ng-container *ngIf="!item.isPreview && !item.isInviteOnly">
            <button mat-stroked-button *ngIf="user.is_admin" color="accent" [id]="'btn-start-trial-' + item.type"
              (click)="trial.emit(item)">
              Start trial
            </button>
            <button mat-stroked-button color="primary" [id]="'btn-subscribe-' + item.type"
              (click)="subscribe.emit(item)">
              Subscribe
            </button>
          </ng-container>
        </mat-card-actions>

      </mat-card>
    </div>
  </div>
</ng-container>